<template>
  <div>
    <div class="position-relative">
      <a
        ref="el"
        class="d-flex align-items-center border p-1 profile"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <UserAvatar :user="user" :variant="'medium'"></UserAvatar>
        <span class="h5 m-0 mx-2 text-nowrap">
          {{ user.firstName | nameInitial }} {{ user.lastName }}
        </span>
      </a>
      <ul class="dropdown-menu menu">
        <li v-if="isLoading"><LoadingMessage></LoadingMessage></li>
        <div v-else>
          <li v-if="bio" v-html="bio" class="dropdown-item-text small">{{ bio }}</li>
          <li v-if="location" v-text="location" class="dropdown-item-text small">{{ location }}</li>
          <li v-if="program" class="dropdown-item-text small">
            <UserConsistency :user="data" :showProgramDue="true"></UserConsistency>
          </li>
          <li v-if="data" class="dropdown-item-text small text-nowrap">
            {{ data.sessionsCompleted }}
            session<span v-if="data.sessionsCompleted > 1">s</span> completed
          </li>
          <li><hr class="dropdown-divider"></li>
          <li @click="showMessageModal = true" class="dropdown-item small" role="button">
            <fa-icon :icon="['fas', 'envelope']" class="me-1"></fa-icon>
            Send message
          </li>
        </div>
      </ul>
    </div>
    <portal to="modal">
      <transition name="modal">
        <MessageModal
          @close="showMessageModal = false"
          v-model="showMessageModal"
          v-if="showMessageModal"
          :user="user"
        ></MessageModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { Dropdown } from 'bootstrap';
import { httpGet } from '@/core/http';

export default {
  name: 'UserProfileBadge',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    UserAvatar: () => import('@/components/UserAvatar'),
    UserConsistency: () => import('@/components/UserConsistency'),
    MessageModal: () => import('@/components/modal/MessageModal'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bio() {
      if (!this.data) return null;
      const { data: { gender, height, unit } } = this;
      const data = [];
      if (gender && gender !== '') {
        if (gender === 'm') data.push('Male');
        if (gender === 'f') data.push('Female');
      }
      if (height && height !== '') {
        const heightUnit = unit === 'metric' ? 'cm' : '';
        data.push(`${height}${heightUnit}`);
      }
      return data.length ? data.join(' | ') : null;
    },
    location() {
      if (!this.data) return null;
      const { location } = this.data;
      return location === '' ? null : location;
    },
    program() {
      if (!this.data) return null;
      const { consistencies, dueDate } = this.data;
      let hasData = false;
      if (consistencies && consistencies.length) {
        if (consistencies[0].total > 0 && consistencies[1].total > 0) {
          hasData = true;
        }
      }
      if (dueDate !== null) {
        hasData = true;
      }
      return hasData;
    },
  },
  methods: {
    async fetchInfo() {
      this.infoIsFetched = true;
      this.isLoading = true;
      try {
        const { userId } = this.user;
        const { data } = await httpGet(`/coaching/client/${userId}/info`);
        this.data = data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      infoIsFetched: false,
      data: null,
      dropdown: null,
      showMessageModal: false,
    };
  },
  mounted() {
    const { el } = this.$refs;

    this.dropdown = new Dropdown(el, {
      autoClose: true,
    });

    el.addEventListener('shown.bs.dropdown', () => {
      if (!this.infoIsFetched) {
        this.fetchInfo();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

.profile {
  border-radius: 99rem;
  text-decoration: none;
}

.profile:focus {
  border-color: rgba(0, 0, 0, .75) !important;
  box-shadow: 0 0 0 .25rem rgb(0 0 0 / 25%);
}

.info {
  border-radius: $borderRadius;
  margin-top: .5rem;

  .info-item {
    min-width: 15rem;
    padding: .5rem 1rem;
    border-top: 1px solid $borderColor;

    &:first-child {
      border-top: 0;
    }
  }
}

.menu {
  top: .5rem !important;

  &::before {
    content: "";
    width: .75rem;
    height: .75rem;
    position: absolute;
    left: 1rem;
    bottom: 100%;
    background-color: white;
    border: 1px solid $borderColor;
    border-width: 1px 0 0 1px;
    transform: rotate(45deg);
    transform-origin: 0 100%;
  }
}
</style>
